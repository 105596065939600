<template>
  <div>
    <b-form-group>
      <label>ロボット選択</label>
      <b-form-select v-model="robot" v-on:change="selectedRos">
        <option :value="null" disabled>-- ロボットを選択してください --</option>
        <option
          v-for="item in robots.edges"
          :key="item.node.id"
          :value="item.node"
        >{{ item.node.uuid }} ({{item.node.ip}})</option>
      </b-form-select>
    </b-form-group>
  </div>
</template>

<script>
import { ROBOT_QUERY } from "../../../components/job-query";

export default {
  data() {
    return {
      robot: null,
      robots: []
    };
  },
  apollo: {
    robots: {
      query: ROBOT_QUERY,
      result({ data, loading }) {},
      error(error) {
        console.error("robots", error);
      }
    }
  },
  methods: {
    selectedRos: function() {
      this.$emit("getRobot", this.robot);
    }
  }
};
</script>
