<template>
  <div>
    <ros-status :ros="this.ros" @rosConnected="rosConnected" @rosUnConnected="rosUnConnected"></ros-status>
    <ros ref="rosObject" :url="this.rosIp" @getRosObject="setRos"></ros>
    <b-card>
      <robot-form v-on:getRobot="setRosIp"></robot-form>
      <b-button @click="reconnection" variant="primary">再接続</b-button>
    </b-card>
    <b-card>
      <google-map ref="gmapObject" v-bind:lat="lat" v-bind:lng="lon" v-bind:degree="degree" />
    </b-card>
    <b-card>
      <b-img src="http://192.168.10.26:8081/stream?topic=/zed/rgb/image_raw_color" fluid-grow />
    </b-card>
    <WeatherForecast></WeatherForecast>
  </div>
</template>
<script>
import ROSLIB from "roslib";

import Ros from "../components/Ros";
import RobotForm from "../components/form/RobotForm";
import RosStatus from "../components/RosStatus";
import WeatherForecast from "../components/WeatherForecast";
import GoogleMap from "../components/GoogleMap";

export default {
  components: {
    Ros,
    WeatherForecast,
    GoogleMap,
    RobotForm,
    RosStatus
  },
  data() {
    return {
      lat: null,
      lon: null,
      degree: 0,
      rosObj: null,
      posTopic: null,
      degreeTopic: null,
      rosIp: null,
      ros: null
    };
  },
  methods: {
    setRosIp(value) {
      this.rosIp = value.ip;
    },
    setRos(value) {
      this.ros = value;
    },
    rosConnected() {
      this.setPos();
      this.setDegree();
    },
    rosUnConnected() {
      // 初期化
      // マップのロボットマーカーを非表示
      this.lat = null;
      this.lon = null;
      this.degree = 0;
    },
    reconnection() {
      this.$refs.rosObject.init();
    },
    setPos() {
      let _this = this;
      this.posTopic = this.$refs.rosObject.createTopicWithMsgType(
        "/legmin_himawari_magellan_rtk/rtk",
        "/legmin_himawari_magellan_rtk/rtk_pos"
      );
      this.posTopic.subscribe(function(message) {
        _this.lat = message.lat;
        _this.lon = message.lon;
      });
    },
    setDegree() {
      let _this = this;
      this.degreeTopic = this.$refs.rosObject.createTopic(
        "/legmin_himawari_mi/magnetism_azimuth"
      );
      this.degreeTopic.subscribe(function(message) {
        _this.degree = message.data;
      });
    }
  },
  beforeDestroy() {
    if (this.ros) {
      this.ros.close();
    }
  }
};
</script>
