import gql from "graphql-tag";

export const JOB_QUERY = gql`
  query($robotIds: [ID!], $startDate: DateTime, $endDate: DateTime) {
    jobs(
      robotId_In: $robotIds
      startDate_Gte: $startDate
      endDate_Lt: $endDate
    ) {
      edges {
        node {
          id
          plan {
            id
            name
          }
          row {
            id
            name
          }
          robot {
            id
            uuid
            ip
            station {
              id
            }
          }
          project {
            id
            name
          }
          field {
            id
            name
          }
          startDate
          endDate
        }
      }
    }
  }
`;

export const PLAN_QUERY = gql`
  query {
    plans {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const PROJECT_QUERY = gql`
  query {
    projects {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const ROBOT_QUERY = gql`
  query {
    robots {
      edges {
        node {
          id
          uuid
          ip
          station {
            id
          }
        }
      }
    }
  }
`;
