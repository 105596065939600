<template>
  <div id="map">
    <gmap-map
      :center="{
        lat: this.mapPos.lat,
        lng: this.mapPos.lng,
      }"
      :zoom="zoom"
      map-type-id="satellite"
      style="width: 100%; height: 800px;"
      :options="options"
      @click="mapClicked"
    >
      <gmap-marker
        v-if="lat != null && lng != null"
        :position="{lat:lat, lng:lng}"
        :clickable="true"
        :icon="{
        path: 'M -2 5 L 0 0 L 2 5 L 0 3 L -2 5 ',
        fillColor: '#FF0000',
        fillOpacity: 0.9,
        strokeColor: '#000033',
        strokeWeight: 1.0,
        scale: 5,
        rotation: this.degree
      }"
      />
      <gmap-marker
        :key="m.id"
        v-for="m in markers"
        :label="String(m.routeNumber)"
        :position="m.position"
        @click="center=m.position"
        :icon="{
        path: 'M-2.5,-2.5h5v5h-5z',
        fillColor: '#fffacd',
        fillOpacity: 0.9,
        strokeColor: '#000033',
        strokeWeight: 1.0,
        scale: 3,
      }"
      ></gmap-marker>
      <gmap-marker
        :key="m.id"
        v-for="m in stationMarkers.start"
        :label="String(m.routeNumber)"
        :position="m.position"
        @click="center=m.position"
        :icon="{
        path: 'M-2.5,-2.5h5v5h-5z',
        fillColor: '#00ffff',
        fillOpacity: 0.9,
        strokeColor: '#000033',
        strokeWeight: 1.0,
        scale: 3,
      }"
      ></gmap-marker>
      <gmap-marker
        :key="m.id"
        v-for="m in stationMarkers.end"
        :label="String(m.routeNumber)"
        :position="m.position"
        @click="center=m.position"
        :icon="{
        path: 'M-2.5,-2.5h5v5h-5z',
        fillColor: '#dda0dd',
        fillOpacity: 0.9,
        strokeColor: '#000033',
        strokeWeight: 1.0,
        scale: 3,
      }"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  props: {
    lat: {
      default: null
    },
    lng: {
      default: null
    },
    degree: {
      default: null
    },
    markers: {
      type: Array,
      default: () => []
    },
    stationMarkers: {
      type: Object,
      default: () => ({ start: [], end: [] })
    },
    propMapPos: {
      type: Object,
      default: () => ({ lat: 35.09263411632531, lng: 138.9066779004375 })
    }
  },
  data() {
    return {
      zoom: 22,
      options: {
        clickableIcons: false,
        disableDefaultUI: true,
        zoomControl: true,
        tilt: 0
      },
      mapPos: { lat: 35.09263411632531, lng: 138.9066779004375 },
      isMove: true
    };
  },
  create() {
    if (this.propMapPos.lat || this.propMapPos.lng) {
      this.mapPos.lat = this.propMapPos.lat;
      this.mapPos.lng = this.propMapPos.lng;
    }
  },
  updated() {
    if (this.lat || this.lng) {
      this.mapPos.lat = this.lat;
      this.mapPos.lng = this.lng;
    }
  },
  watch: {
    propMapPos: function() {
      if (this.propMapPos.lat || this.propMapPos.lng) {
        this.mapPos.lat = this.propMapPos.lat;
        this.mapPos.lng = this.propMapPos.lng;
      }
    }
  },
  methods: {
    mapClicked(e) {
      this.$emit("mapClicked", this.lat, this.lng);
    }
  }
};
</script>

