<template>
  <b-alert v-model="showDismissibleAlert" dismissible fade :variant="variant">{{ this.statusDisp }}</b-alert>
</template>

<script>
export default {
  props: {
    url: {
      default: null
    },
    ros: {
      default: null
    }
  },

  data() {
    return {
      status: "未接続",
      variant: "warning",
      showDismissibleAlert: false
    };
  },
  computed: {
    statusDisp: function() {
      return this.status;
    }
  },
  watch: {
    "ros.isConnected": function() {
      if (this.ros.isConnected) {
        this.showAlert("接続完了", "success");
        this.$emit("rosConnected");
      } else {
        this.showAlert("接続できません", "warning");
        this.$emit("rosUnConnected");
      }
    }
  },
  methods: {
    showAlert(msg, variant) {
      this.status = msg;
      this.variant = variant;
      this.showDismissibleAlert = true;
    }
  }
};
</script>
