import gql from 'graphql-tag'

export const GET_WEATHER_FORECAST = gql`
mutation createWeatherForecast($input: WeatherForecastInput!){
  createWeatherForecast(input:$input){
    weatherForecast{
      id
      wxData
      getDate
    }
  }
}
`
