<template>
  <b-card>
    <div>
      <h1 class="text-center">{{areaName}}</h1>
      <h2 class="text-center">{{date}}</h2>
    </div>
    <div>
      <b-table-simple hover small responsive borderless class="weatherPerHour">
        <b-tbody v-if="selectedEveryHourForecast.length">
          <b-td v-for="(item , index) in selectedEveryHourForecast" :key="index">
            <b>{{getstrTime(item.tstr)}}</b>
            <p style="color:black;font-size: 10pt">{{item.WXTAG}}</p>
            <img class="d-block mx-auto" style="width: 50px; height: 40px;" :src="getIcon(item.WX)" />
            <p style="color:red">{{`${item.TEMP}°C`}}</p>
            <p style="color:blue">{{`${item.PREC}mm/h`}}</p>
            <p style="color:black">{{wnddir[item.WNDDIR]}}</p>
            <p style="color:black">{{`${item.WNDSPD}m/s`}}</p>
          </b-td>
        </b-tbody>
        <b-tbody v-else>1 時間ごとの予報はありません。</b-tbody>
      </b-table-simple>
    </div>
    <div>
      <b-table-simple hover small responsive borderless class="weatherPerDay">
        <b-tbody>
          <b-td v-for="(item , index) in everyDayForecast" :key="index">
            <b-button class="DailyForecastButton" v-on:click="selectedDateForecast(item.tstr)">
              <b>
                {{dateFormatter(item.tstr, "MM/DD")}}
                <br />
                {{getDayOfWeek(item.tstr)}}
              </b>
              <img
                class="d-block mx-auto"
                style="width: 55px; height: 43px;"
                :src="getIcon(item.WX)"
              />
              <p style="color:black;font-size: 10pt">{{item.WXTAG}}</p>
              <span style="color:red;font-size: 10pt">{{`${item.TMAX}°C `}}</span>
              <span style="color:blue;font-size: 10pt">{{`${item.TMIN}°C`}}</span>
            </b-button>
          </b-td>
        </b-tbody>
      </b-table-simple>
    </div>
  </b-card>
</template>


<script>
import { GET_WEATHER_FORECAST } from "../../components/get-weather-forecast";
import moment from "moment";

const ICON_URL = "https://tpf.weathernews.jp/wxicon/152/";
const START_POINT_OF_TIME_FORMAT = 11;
const END_POINT_OF_TIME_FORMAT = 16;
const FIXED_LAT = 35.09263411632531;
const FIXED_LON = 138.90667790043749;
const DAY_OF_WEEK_STR = ["日", "月", "火", "水", "木", "金", "土"];
const FORECAST_RANGE = 24;

export default {
  data() {
    return {
      lat: FIXED_LAT,
      lon: FIXED_LON,
      areaName: "",
      date: "",
      unixTime: 0,
      everyHourForecast: [],
      everyDayForecast: [],
      wnddir: [
        "方位なし",
        "北北東",
        "北東",
        "東北東",
        "東",
        "東南東",
        "南東",
        "南南東",
        "南",
        "南南西",
        "南西",
        "西南西",
        "西",
        "西北西",
        "北西",
        "北北西",
        "北"
      ],
      selectedEveryHourForecast: []
    };
  },
  created() {
    this.init();
  },
  methods: {
    init: function() {
      const { lat, lon } = this.$data;
      this.$apollo
        .mutate({
          mutation: GET_WEATHER_FORECAST,
          variables: {
            input: {
              lat: lat,
              lon: lon
            }
          }
        })
        .then(result => {
          if (result === undefined) {
            throw new Error("weather data is undefined");
          }
          const wxDataString =
            result.data.createWeatherForecast.weatherForecast.wxData;
          const wxDataJson = JSON.parse(wxDataString);

          this.areaName = wxDataJson.geo.areaname;
          this.unixTime = wxDataJson.srf_attr.time;
          this.everyHourForecast = wxDataJson.srf;
          this.everyDayForecast = wxDataJson.mrf;

          this.selectedDateForecast(wxDataJson.srf_attr.remark);
        });
    },
    getstrTime: function(tstr) {
      return tstr.substring(
        START_POINT_OF_TIME_FORMAT,
        END_POINT_OF_TIME_FORMAT
      );
    },
    dateFormatter: function(tstr, pattern) {
      return moment(tstr).format(pattern);
    },
    getDayOfWeek: function(tstr) {
      const dayOfWeekNum = new Date(tstr).getDay();
      return DAY_OF_WEEK_STR[dayOfWeekNum];
    },
    getIcon: function(iconNum) {
      return ICON_URL + iconNum + ".png";
    },
    selectedDateForecast: function(tstr) {
      this.date = tstr;
      let results = [];

      for (let i = 0; i < this.everyHourForecast.length; i++) {
        const eachDate = this.dateFormatter(
          this.everyHourForecast[i].tstr.substr(0, 10),
          "YYYY-MM-DD"
        );

        if (eachDate == tstr) {
          const bufs = this.everyHourForecast.slice(i, i + FORECAST_RANGE);
          if (bufs.length == FORECAST_RANGE) {
            results = bufs;
          }
          break;
        }
      }

      this.selectedEveryHourForecast = results;
    }
  }
};
</script>

<style>
.weatherPerHour {
  text-align: center;
}
.weatherPerDay {
  text-align: center;
}
.DailyForecastButton {
  height: 200px;
  width: 120px;
  background-color: white;
  border-color: white;
}
.DailyForecastButton:focus {
  background-color: aliceblue;
  border-color: skyblue;
}
</style>
